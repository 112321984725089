
import Vue, { PropType } from "vue";

import MyFormattedDate from "@/components/MyFormattedDate.vue";

import { Procedure } from "@/interfaces";

export default Vue.extend({
  components: {
    MyFormattedDate
  },
  props: {
    procedure: {
      type: Object as PropType<Procedure>,
      required: true
    }
  },
  computed: {
    type(): string {
      if(this.procedure.person_change){
        if(this.procedure.person_change.is_reduction){
          return "入居者減";
        }
      }

      return "入居者増";
    }
  }
});
