
import Vue, { PropType } from "vue";

import MyFormattedDate from "@/components/MyFormattedDate.vue";

import { Procedure } from "@/interfaces";

export default Vue.extend({
  components: {
    MyFormattedDate,
  },
  props: {
    procedure: {
      type: Object as PropType<Procedure>,
      required: true,
    },
  },
});
