
import Vue from "vue";

import MyInformationChange from "@/components/procedure/MyInformationChange.vue";
import MyPersonChange from "@/components/procedure/MyPersonChange.vue";
import MyCarChange from "@/components/procedure/MyCarChange.vue";
import MyGuarantorChange from "@/components/procedure/MyGuarantorChange.vue";

import { Attachment, Procedure, Snackbar } from "@/interfaces";

import http from "@/http";

import axios from "axios";

import { mdiPrinter } from "@mdi/js";
import { mapState } from "vuex";

export interface Body {
  name: string;
  idList: string;
  desc: string;
}
export interface DataType {
  procedure?: Procedure;
}

export default Vue.extend({
  components: {
    MyInformationChange,
    MyPersonChange,
    MyCarChange,
    MyGuarantorChange,
  },
  data(): DataType {
    return {
      procedure: undefined,
    };
  },
  computed: {
    ...mapState(["user"]),
    isDoing() {
      if (this.procedure == null) {
        return false;
      }

      if (this.procedure.status === "進行中") {
        return true;
      }

      return false;
    },
    isDone() {
      if (this.procedure == null) {
        return false;
      }

      if (this.procedure.status === "完了") {
        return true;
      }

      return false;
    },
    icon() {
      return {
        mdiPrinter,
      };
    },
    breadcrumbs() {
      let text = "";
      if (this.procedure) {
        text = `# ${this.procedure.id} ${this.procedure.contract.room.building.name}
      ${this.procedure.contract.room.display_number}`;
      }
      return [
        {
          text: "ホーム",
          disabled: false,
          to: "/",
        },
        {
          text: "各種手続き",
          disabled: false,
          to: "/procedures",
        },
        {
          text,
          disabled: true,
        },
      ];
    },
  },
  async mounted() {
    const { id } = this.$route.params;

    const url = `/procedures/${id}`;

    const { data } = await http.get<Procedure>(url);

    this.procedure = data;
  },
  methods: {
    async click() {
      if (this.procedure == null) {
        return;
      }
    },
    async cancel() {
      if (this.procedure == null) {
        return;
      }

      if (!window.confirm("本当にキャンセルしますか?")) {
        return;
      }

      const url = `/procedures/${this.procedure.id}`;

      const { data } = await http.put<Procedure>(url, {
        status: 9,
      });

      this.procedure = data;
    },
    async done() {
      if (this.procedure == null) {
        return;
      }

      if (
        !window.confirm(
          "情報システム部のみ送信できます。本当に完了通知を送信しますか?"
        )
      ) {
        return;
      }

      const url = `/procedures/${this.procedure.id}`;

      const { data } = await http.put<Procedure>(url, {
        status: 3,
      });

      this.procedure = data;
    },
    async request() {
      if (this.procedure == null) {
        return;
      }

      const url = `/procedures/${this.procedure.id}`;

      const { data } = await http.put<Procedure>(url, {
        status: 2,
      });

      this.procedure = data;

      const { VUE_APP_TRELLO_LIST_ID, VUE_APP_REDIRECT_URL } = process.env;

      const { contract, type } = this.procedure;

      const desc = `
${VUE_APP_REDIRECT_URL}/procedure/${this.procedure.id}

from:${this.user.email}
`;

      const body: Body = {
        name: `${type}:${contract.room.building.name} ${contract.room.display_number}`,
        idList: `${VUE_APP_TRELLO_LIST_ID}`,
        desc,
      };

      await this.post(body);
    },
    async post(data: Body) {
      const { VUE_APP_TRELLO_KEY, VUE_APP_TRELLO_TOKEN } = process.env;

      const url = `https://api.trello.com/1/cards`;

      const params = {
        key: VUE_APP_TRELLO_KEY,
        token: VUE_APP_TRELLO_TOKEN,
      };

      await axios.post(url, data, {
        params,
      });

      const snackbar: Snackbar = {
        show: true,
        content: "リクエストを送信しました",
        color: "success",
      };

      this.$store.commit("setSnackbar", snackbar);
    },
    async remove() {
      if (this.procedure == null) {
        return;
      }

      if (!window.confirm("本当に削除しますか?")) {
        return;
      }

      const url = `/procedures/${this.procedure.id}`;

      await http.delete(url);

      const snackbar: Snackbar = {
        show: true,
        content: "削除しました",
        color: "success",
      };

      this.$store.commit("setSnackbar", snackbar);

      this.$router.push("/procedures");
    },
    download(attachment: Attachment) {
      window.location.href = attachment.url;
    },
  },
});
