
import Vue, { PropType } from "vue";

import MyFormattedDate from "@/components/MyFormattedDate.vue";

import { Procedure } from "@/interfaces";

import dayjs from "dayjs";

export default Vue.extend({
  components: {
    MyFormattedDate,
  },
  props: {
    procedure: {
      type: Object as PropType<Procedure>,
      required: true,
    },
  },
  computed: {
    age(): number {
      if (this.procedure.guarantor_change == null) {
        return 0;
      }

      const birthday = dayjs(this.procedure.guarantor_change.birthday);

      return dayjs().diff(birthday, "year");
    },
  },
});
